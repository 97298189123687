import { RouteRecordRaw } from 'vue-router'

const routes: Array<RouteRecordRaw> = [
  {
    path: '/', redirect: '/login'
  },
  {
    path: '/login', name: 'Login',
    component: () => import('@views/login/LoginView.vue')
  },
  {
    path: '/middleware', name: 'Middleware',
    component: () => import('@views/MiddlewareView.vue')
  },
]

export default { routes }
