import { RouteRecordRaw } from 'vue-router'
import Layout from '@/layout/LayoutView.vue'

const routes: Array<RouteRecordRaw> = [
  // {
  //   path: '/configuration-management', name: 'ConfigurationManagement',
  //   component: Layout, redirect: '/configuration-management/device',
  //   meta: { title: '组态管理', permission: '5' },
  //   children: [
  //     {
  //       path: 'device', name: 'DeviceConfiguration',
  //       meta: { title: '设备组态' },
  //       component: () => import('@views/self-configuration/DeviceConfigManage.vue'),
  //     },
  //     {
  //       path: 'scenes', name: 'SceneConfiguration',
  //       meta: { title: '场景组态' },
  //       component: () => import('@views/WorkView.vue'),
  //     },
  //   ]
  // },
  {
    path: '/configuration-management/edit-device', name: 'EditDeviceConfiguration',
    meta: { title: '设备组态', isMenu: false },
    component: () => import('@views/self-configuration/EditDeviceSelf.vue'),
  },
  {
    path: '/configuration-management/show-device', name: 'ShowDeviceConfiguration',
    meta: { title: '设备组态', isMenu: false },
    component: () => import('@views/self-configuration/ShowDeviceSelf.vue'),
  },
  {
    path: '/configuration-management/edit-scene', name: 'EditSceneConfiguration',
    meta: { title: '场景组态', isMenu: false },
    component: () => import('@views/self-configuration/EditSceneSelf.vue'),
  },
  {
    path: '/configuration-management/show-scene', name: 'ShowSceneConfiguration',
    meta: { title: '场景组态', isMenu: false },
    component: () => import('@views/self-configuration/ShowSceneSelf.vue'),
  },
]

export default { routes, menu: true }
