import { createModule } from '@/store'
import { User } from '@/typings/user'
import { getUserInfoAPI } from '@/api/auth'
import { BOrganizationType, getOrganizationByOIdAPI, getProjectInfoAPI } from '@/api/organization'
import settings from '@/settings'
import { ElMenu, ElNotification } from 'element-plus'

export default createModule({
  namespaced: true,
  state: {
    // 用户信息
    userInfo: {} as User,
    projectInfo: {} as BOrganizationType & { [p: string]: any },
    websocket: null,
    // 当前选中的菜单
    activeMenu: '',
  },
  mutations: {
    setUserInfo (state, value) {
      state.userInfo = value
    },
    setProjectInfo (state, value) {
      state.projectInfo = value
    },
    setActiveMenu (state, value) {
      state.activeMenu = value
    },
    setWebsocket (state, value) {
      state.websocket = new WebSocket(settings.WEBSOCKET + '/' + value)
      state.websocket.onopen = function () {
        console.log('已连接')
      }
      state.websocket.onclose = function () {
        console.log('已断开')
      }
      state.websocket.onopen = function () {
        console.log('已连接')
      }
      state.websocket.onmessage = function (e) {
        const data = JSON.parse(e.data)
        if (data.code !== 200) {
          ElNotification.warning(data.message)
        }
      }
    },
  },
  actions: {
    // TODO 更新用户信息
    updateUserInfo ({ commit, dispatch }) {
      return new Promise((resolve, reject) => {
        getUserInfoAPI().then(res => {
          if (res.result) {
            res.result().then(async function (rdata) {
              const {
                auth,
                data
              } = rdata.payload
              let permission = []
              if (auth) {
                permission = auth.map(function (item) {
                  return item[0] === 'D' ? item.substring(1, item.length) : item
                })
                if (data.isManage) {
                  permission.push('10', '16', '17')
                }
              }
              data.role = data.roleId.toString()
              data.permission = new Set(permission)
              commit('setUserInfo', data)
              await dispatch('getProjectInfo', data.organizationId)
              commit('setWebsocket', data.id)
              resolve({ state: true })
            })
          } else {
            // 模拟用户信息
            const data = res.data.payload.data
            data.role = '1'
            data.permission = new Set()
            commit('setUserInfo', data)
            resolve({ state: true })
          }
        }).catch(reject)
      })
    },
    getProjectInfo ({ commit, state }, value) {
      return new Promise(function (resolve, reject) {
        if (Object.keys(state.projectInfo).length > 0) {
          resolve(undefined)
        } else {
          getOrganizationByOIdAPI({ organizationId: value }).then(res => {
            res.result().then(rdata => {
              const data = rdata.payload.data
              const defaultLogo = require('@images/logo/logo-white.png')
              const defaultTitle = '京灌科技管理平台'
              if (value === 1) {
                data.logo = defaultLogo
                data.title = '京灌科技管理平台'
                data.remark = '灌区信息化平台，通过科学的顶层设计和前沿的信息技术，实现具有“水利信息透彻感知、水利数据全面整合、 水利业务深度打通、水利管理高度智能”四大特色的现代化水利管理方式。平台立足智慧水利建设面向未来5~10年的“中国智慧城市”、“中国大数据交易”发展中，适应智慧水利发展九大业务需求的要求，具备超强的系统扩展性、平台兼容性和技术先进性。'
                state.projectInfo = data
                resolve(undefined)
              } else {
                if (data.isProject) {
                  data.title = data.organizationName
                  if (!data.logo) {
                    data.logo = defaultLogo
                    data.remark = '暂无简介'
                    data.picture = settings.IMAGE_PREFIX + data.picture
                    state.projectInfo = data
                    resolve(undefined)
                  } else {
                    data.logo = settings.IMAGE_PREFIX + data.logo
                    state.projectInfo = data
                    resolve(undefined)
                  }
                  state.projectInfo = data
                  resolve(undefined)
                } else {
                  getProjectInfoAPI({ organizationId: value }).then(function (res) {
                    res.result(false).then(function (rdata) {
                      const projects = rdata.payload.data
                      projects[0].logo && (data.logo = settings.IMAGE_PREFIX + projects[0].logo)
                      data.title = projects[0].organizationName
                      // data.logo = rdata.payload.data.filter(i => i.logo !== null && i.logo !== '')
                      // data.title = rdata.payload.data.filter(i => i.organizationName !== null && i.organizationName !== '')
                      data.value = rdata.payload.data.filter(i => i.logo !== null && i.organizationName !== null)
                      // for (let i = projects.length - 1; i > 0; i--) {
                      //   if (projects[i].logo) {
                      //     data.logo = settings.IMAGE_PREFIX + data.logo
                      //     data.title = projects.organizationName
                      //     break
                      //   }
                      // }
                      !data.logo && (data.logo = defaultLogo)
                      !data.title && (data.title = defaultTitle)
                      !data.company && (data.company = defaultTitle)
                      !data.companyLogo && (data.companyLogo = defaultTitle)
                      state.projectInfo = data
                      resolve(undefined)
                    }).finally(() => {
                      resolve(undefined)
                    })
                  })
                }
              }
            })
          })
        }
      })
    }
  },
  getters: {
    userInfo: state => state.userInfo,
    activeMenu: state => state.activeMenu,
    projectInfo: state => state.projectInfo,
  },
})
