import { RouteRecordRaw } from 'vue-router'
import Layout from '@/layout/LayoutView.vue'

const routes: Array<RouteRecordRaw> = [
  {
    path: '/custom-report', name: 'customReport',
    component: Layout, redirect: '/custom-report/index',
    meta: { title: '自定义报表', permission: '7' },
    children: [
      {
        path: 'index', name: 'CustomReportIndex',
        meta: { title: '自定义报表' },
        component: () => import('@views/customReport/CustomReportIndex.vue'),
      },
      {
        path: 'add-report', name: 'AddCustomReport',
        meta: { title: '新建报表', isMenu: false, activeMenu: 'CustomReportIndex' },
        component: () => import('@views/customReport/AddCustomReport.vue'),
        props: true
      },
      {
        path: 'edit-report', name: 'EditCustomReport',
        meta: { title: '修改报表', isMenu: false, activeMenu: 'CustomReportIndex' },
        component: () => import('@views/customReport/EditCustomReport.vue'),
        props: true
      },
      // {
      //   path: 'configure', name: 'ReportConfiguration',
      //   meta: { title: '报表配置' },
      //   component: () => import('@views/WorkView.vue'),
      // },
    ]
  }
]

export default { routes, menu: true }
