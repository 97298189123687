import { RouteRecordRaw } from 'vue-router'
import Layout from '@/layout/LayoutView.vue'

const routes: Array<RouteRecordRaw> = [
  {
    path: '/mobile-management', name: 'MobileManagement',
    component: Layout, redirect: '/mobile-management/index',
    meta: { title: '充值机管理', permission: '9' },
    children: [
      {
        path: 'water-user', name: 'WaterUserManagement',
        meta: { title: '用水户管理', permission: '27' },
        component: () => import('@views/mobile/WaterUser/WaterUserManage.vue'),
      },
      {
        path: 'card-management', name: 'CardManagement',
        meta: { title: '卡管理', permission: '72' },
        component: () => import('@/views/mobile/WaterUser/CardManage.vue'),
      },
      {
        path: 'show-card', name: 'ShowCard',
        meta: { title: '卡信息', activeMenu: 'CardManagement', isMenu: false, permission: '72' },
        component: () => import('@/views/mobile/WaterUser/ShowCard.vue'),
      },
      {
        path: 'account-role', name: 'MobileAccountRoleManagement',
        meta: { title: '账号角色管理', permission: '89' },
        component: () => import('@views/mobile/userRole/UserRoleManage.vue'),
      },
      {
        path: 'recharge-machine', name: 'RechargeMachineManagement',
        meta: { title: '充值机管理', permission: '31' },
        component: () => import('@views/mobile/RechargeMachineManage/RechargeMachineManage.vue'),
      },
      {
        path: 'well-house', name: 'WellHouseManagement',
        meta: { title: '井房管理', permission: '77' },
        component: () => import('@views/mobile/well/WellManage.vue'),
      },
      // {
      //   path: 'mobile-custom-report', name: 'MobileCustomReport',
      //   meta: { title: '自定义报表' },
      //   component: () => import('@views/WorkView.vue'),
      // },
    ]
  }
]

export default { routes, menu: true }
