import { RouteRecordRaw } from 'vue-router'
import Layout from '@/layout/LayoutView.vue'

const routes: Array<RouteRecordRaw> = [
  {
    path: '/alarm', name: 'Alarm',
    component: Layout, redirect: '/alarm/index',
    meta: { title: '报警联动', permission: '6' },
    children: [
      {
        path: 'template', name: 'TemplateTrigger',
        meta: { title: '模版触发器', permission: '59' },
        component: () => import('@views/reportPolice/template.vue'),
      },
      {
        path: 'independent', name: 'IndependentTrigger',
        meta: { title: '独立触发器', permission: '64' },
        component: () => import('@views/reportPolice/indePendent.vue'),
      },
      {
        path: 'configuration', name: 'AlarmConfiguration',
        meta: { title: '报警配置', permission: '68' },
        component: () => import('@views/reportPolice/alarmConfiguration.vue'),
      },
    ]
  }
]
export default { routes, menu: true }
