import m from 'moment'

m.locale('zh-cn')

/* eslint-disable */
export enum DateFormat {
  y = 'YYYY',
  m = 'M',
  ym = 'YYYY-MM',
  etime = 'HH:mm',
  time = 'HH:mm:ss',
  date = 'YYYY-MM-DD',
  datetime = 'YYYY-MM-DD HH:mm:ss'
}
/* eslint-disable */

/**
 * 获取近几天
 * @param day
 */
export function recentDate (day: number): [string, string] {
  const today = m()
  const before = m().add(-day, 'days')
  return [before.format(DateFormat.date + ' ' +'00:00:00'), today.format(DateFormat.date + ' ' +'00:00:00')]
}
/**
 * 获取近几天
 * @param day
 */
export function recentDateTime (day: number): [string, string] {
  const today = m()
  const before = m().add(-day, 'days')
  return [before.format(DateFormat.datetime), today.format(DateFormat.datetime)]
}
export function getHourNow () {
  const hourArr = []
  const nowHour = new Date().getHours()
  let i: any
  for (i = 0; i < nowHour; i++) {
    if (i < 10) {
      i = '0' + i
    }
    hourArr.push(i + ':00')
  }
  return hourArr
}
// date 代表指定的日期，格式：2018-09-27// day 传-1表始前一天，传1表始后一天// JS获取指定日期的前一天，后一天
export function getNextDate(date: string, day: number) {
  const dd = new Date(date)
  dd.setDate(dd.getDate() + day)
  const y = dd.getFullYear()
  const m = dd.getMonth() + 1 < 10 ? '0' + (dd.getMonth() + 1) : dd.getMonth() + 1
  const d = dd.getDate() < 10 ? '0' + dd.getDate() : dd.getDate()
  return [y + '-' + m + '-' + d, date]
}
// eg:
// getNextDate('2018-09-27', -1)

export function getBeforeYear(date: string, num: number) {
  const dd = new Date(date)
  dd.setFullYear(dd.getFullYear() + num)
  const y = dd.getFullYear()
  const m = dd.getMonth() + 1 < 10 ? '0' + (dd.getMonth() + 1) : dd.getMonth() + 1
  // const d = dd.getDate() < 10 ? '0' + dd.getDate() : dd.getDate()
  return [y + '-' + m, date]
}
export const shortcuts = [
  { text: '近7天', value: recentDate(7) },
  { text: '近30天', value: recentDate(30) },
]
export const moment = m
