import request from '@/utils/request'
import qs from 'qs'
// import { AxiosResponse } from 'axios'
// import { APIResult } from '@/typings'

// export const testAPI = (params: any): APIResult<{
//   token: string, user: { [p: string]: any }
// }> => request.post('/sys/login', params)

// testAPI({}).then(function (res) {
//   res.result().then(function (rdata) {
//     const user = rdata.payload.data.user
//   })
// })

/**
 * 登录
 * @param params
 */
export const loginAPI = (params: any) => request.post('/sys/login', params)

/**
 * 登出
 */
export const logoutAPI = () => request.post('/sys/logout')

/**
 * 获取用户信息
 */
export const getUserInfoAPI = () => request.get('/user/showHimself')
// 模拟获取用户
// export const getUserInfoAPI = () => {
//   const promise = new Promise(function (resolve) {
//     resolve({ data: { code: 200, payload: { data: { id: 1 } } } })
//   })
//   return <Promise<AxiosResponse<any>>>promise
// }

/**
 * 修改密码
 */
export const ModifyPwdForm = {
  password: '',
  newPassWord: '',
  confirmPassWord: ''
}
export type ModifyPwdFormType = typeof ModifyPwdForm
export const modifyPwdAPI = (params: ModifyPwdFormType) => request.post('/user/updatePassword', qs.stringify(params))
