import { RouteRecordRaw } from 'vue-router'
import Layout from '@/layout/LayoutView.vue'

const routes: Array<RouteRecordRaw> = [
  {
    path: '/overview', name: 'MonitorTheBigScreen', redirect: '/overview/screen-water',
    component: Layout,
    meta: { title: '监控大屏', permission: '1' },
    children: [
      {
        path: 'screen-water', name: ' ScreenWater', redirect: '/monitor-screen/gis',
        meta: { title: '灌区信息化平台', permission: '11' },
      },
      {
        path: 'home', name: 'HomeView',
        component: () => import('@views/overview/HomeView.vue'),
        meta: { title: '首页', permission: '11', isMenu: false },
      },
    ]
  },
]

export default { routes, menu: true }
