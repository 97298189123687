import { RouteRecordRaw } from 'vue-router'
import LayoutScreen from '@views/overview/Agricultural-irrigation-screen/LayoutScreen.vue'
// import Layout from '@/layout/LayoutView.vue'

const routes: Array<RouteRecordRaw> = [
  {
    path: '/monitor-screen', name: '',
    component: LayoutScreen,
    // meta: { title: '监控大屏' },
    children: [
      {
        path: 'system-description', name: 'SystemDescription',
        meta: { title: '系统概述' },
        component: () => import('@views/overview/Agricultural-irrigation-screen/page/SystemDescription.vue'),
      },
      {
        path: 'gis', name: 'GisView',
        meta: { title: 'GIS地图' },
        component: () => import('@views/overview/Agricultural-irrigation-screen/page/GisView.vue'),
      },
      // {
      //   path: 'gis', name: 'GisView',
      //   meta: { title: 'GIS地图' },
      //   component: () => import('@views/overview/Agricultural-irrigation-screen/page/GisDemo.vue'),
      // },
      {
        path: 'realTime-equipment-overview', name: 'EquipmentOverview',
        meta: { title: '设备总览', activeMenu: 'RealTimeData' },
        component: () => import('@views/overview/Agricultural-irrigation-screen/page/RealTimeData/EquipmentOverview.vue'),
      },
      {
        path: 'realTime-variable-mode-query', name: 'RealTimeVariableModeQuery',
        meta: { title: '实时数据-变量方式查询', activeMenu: 'RealTimeData' },
        component: () => import('@views/overview/Agricultural-irrigation-screen/page/RealTimeData/VariableModeQuery.vue'),
      },
      {
        path: 'realTime-station-mode-query', name: 'RealTimeStationModeQuery',
        meta: { title: '实时数据-测站方式查询', activeMenu: 'RealTimeData' },
        component: () => import('@views/overview/Agricultural-irrigation-screen/page/RealTimeData/StationModeQuery.vue'),
      },
      {
        path: 'realTime-data-detail', name: 'RealTimeDataDetail',
        meta: { title: '实时数据详情', activeMenu: 'RealTimeData' },
        component: () => import('@views/overview/Agricultural-irrigation-screen/page/RealTimeData/RealTimeDataDetail.vue'),
      },
      {
        path: 'history-variable-mode-query', name: 'HistoryVariableModeQuery',
        meta: { title: '历史数据-变量方式查询', activeMenu: 'HistoryData' },
        component: () => import('@views/overview/Agricultural-irrigation-screen/page/HistoryData/VariableModeQuery.vue'),
      },
      {
        path: 'history-station-mode-query', name: 'HistoryStationModeQuery',
        meta: { title: '历史数据-测站方式查询', activeMenu: 'HistoryData' },
        component: () => import('@views/overview/Agricultural-irrigation-screen/page/HistoryData/StationModeQuery.vue'),
      },
      {
        path: 'history-remote-control-history', name: 'RemoteControlHistory',
        meta: { title: '历史数据-远程控制历史', activeMenu: 'HistoryData' },
        component: () => import('@views/overview/Agricultural-irrigation-screen/page/HistoryData/RemoteControlHistory.vue'),
      },
      {
        path: 'day-report-data', name: 'DayReportData',
        meta: { title: '报表数据-日报表', activeMenu: 'ReportData' },
        component: () => import('@views/overview/Agricultural-irrigation-screen/page/ReportData/DayReportData.vue'),
      },
      {
        path: 'month-report-data', name: 'MonthReportData',
        meta: { title: '报表数据-月报表', activeMenu: 'ReportData' },
        component: () => import('@views/overview/Agricultural-irrigation-screen/page/ReportData/MonthReportData.vue'),
      },
      {
        path: 'year-report-data', name: 'YearReportData',
        meta: { title: '报表数据-年报表', activeMenu: 'ReportData' },
        component: () => import('@views/overview/Agricultural-irrigation-screen/page/ReportData/YearReportData.vue'),
      },
      {
        path: 'alarm-record', name: 'AlarmRecord',
        meta: { title: '报警记录' },
        component: () => import('@views/overview/Agricultural-irrigation-screen/page/AlarmRecord.vue'),
      },
      {
        path: 'camera-view', name: 'CameraView',
        meta: { title: '摄像头' },
        component: () => import('@views/overview/Agricultural-irrigation-screen/page/CameraView.vue'),
      },
      // {
      //   path: 'screen-dynamic-component', name: 'ScreenDynamicComponent',
      //   meta: { title: '大屏动态组件', activeMenu: 'RealTimeData' },
      //   component: () => import('@views/overview/Agricultural-irrigation-screen/ScreenDynamicComponent.vue'),
      // },
      {
        path: '/dynamic-component', name: 'DynamicComponent',
        meta: { title: '大屏动态组件', activeMenu: 'RealTimeData' },
        component: () => import('@views/DynamicComponent.vue')
      }
    ]
  },
]

export default { routes }
