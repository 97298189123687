import { RouteRecordRaw } from 'vue-router'
import Layout from '@/layout/LayoutView.vue'

const routes: Array<RouteRecordRaw> = [
  {
    path: '/platform', name: 'PlatformManagement',
    component: Layout, redirect: '/platform/user-management',
    meta: { title: '组织管理', permission: '10' },
    children: [
      {
        path: 'user-management', name: 'UserManagement',
        meta: { title: '用户管理', permission: '16' },
        component: () => import('@views/platform/userManage/UserManage.vue'),
      },
      {
        path: 'authority-management', name: 'AuthorityManagement',
        meta: { title: '权限管理', permission: '17' },
        // component: () => import('@views/WorkView.vue'),
        component: () => import('@views/platform/role/RoleManage.vue'),
      },
      {
        path: 'config-authority', name: 'ConfigAuthority',
        meta: { title: '权限配置', permission: '17', activeMenu: 'AuthorityManagement', isMenu: false },
        component: () => import('@views/platform/role/ConfigRole.vue'),
      },
      {
        path: 'organizational-management', name: 'OrganizationalManagement',
        meta: { title: '层级管理', permission: '35' },
        component: () => import('@views/platform/organizational/OrganizationalManage.vue'),
      },
      {
        path: 'add-organizational', name: 'AddOrganizational',
        meta: { title: '添加组织', permission: '37', activeMenu: 'OrganizationalManagement', isMenu: false },
        component: () => import('@views/platform/organizational/AddOrganizational.vue'),
      },
      {
        path: 'modify-organizational', name: 'ModifyOrganizational',
        meta: { title: '修改组织', permission: '36', activeMenu: 'OrganizationalManagement', isMenu: false },
        component: () => import('@views/platform/organizational/AddOrganizational.vue'),
      },
    ]
  }
]

export default { routes, menu: true }
