import request from '@/utils/request'
// import { AxiosResponse } from 'axios'
import { APIResult, Pages } from '@/typings'

export const BOrganization = {
  company: '', // 公司名称
  companyLogo: '', // 公司logo
  id: 0,
  isProject: false, // 是否是项目
  logo: '', // logo
  organizationName: '', // 组织名称
  parent: 0, // 父级id
  remark: '', // 备注
  picture: '',
  cardKey: 'FFFFFFFFFFFF'
}
export type BOrganizationType = typeof BOrganization

/**
 * 获取当前账号的组织列表
 */
export type OrganizationNode = { id: number, organizationName: string, list: OrganizationNode[], parent: number }
export const getOrganizationNodeListAPI = (): APIResult<OrganizationNode> => request.get('/organization/getOrganizationList')

/**
 * 获取条件下的组织列表
 */
export const getOrganizationListAPI = (params: {
  organizationId?: number, organizationName?: string
}): APIResult<any, Pages> => request.get('/organization/getOrganizationMessage', { params })

/**
 * 添加组织
 */
export const AddOrganization = {
  addressCodes: [],
  addressNames: [],
  organization: BOrganization
}
export type AddOrganizationType = typeof AddOrganization
export const addOrganizationAPI = (params: AddOrganizationType) => request.post('/organization/addOrganization', params)

/**
 * 修改组织
 * @param params
 */
export const modifyOrganizationAPI = (params: BOrganizationType) => request.get('/organization/changeOrganization', { params })

/**
 * 获取项目下的区域代码
 * @param params
 */
export const getAreaListByOIdAPI = (params: {
  organizationId: number
}) => request.get('/organization/getAddressCodeAndNameByProjectId', { params })

/**
 * 添加区域到项目
 * @param params
 */
export const addAreaToOrganizationAPI = (params: {
  organizationId: number, addressCode: string
}) => request.get('/organization/addAddressByProject', { params })

/**
 * 删除一个区域
 * @param params
 */
export const delAreaOfOrganizationAPI = (params: {
  organizationId: number, addressCode: string
}) => request.get('/organization/removeAddressByProject', { params })

/**
 * 根据Id获取组织信息
 * @param params
 */
export const getOrganizationByOIdAPI = (params: {
  organizationId: number
}) => request.get('/organization/byId', { params })

/**
 * 删除组织
 * @param params
 */
export const delOrganizationByIdAPI = (params: { organizationId: number }) => request.get('/organization/removeOrganization', { params })

/**
 * 获取账号下带设备的组织信息
 * @param params
 */
// export const getOrganizationAndDeviceAPI = (params: { organizationId: number }) => request.get('/organization/getOrganizationAndDeviceByOrganizationId', { params }).then(function (res) {
// })
export const getOrganizationAndDeviceAPI = (params: { organizationId: number }) => request.get('/organization/getOrganizationAndDeviceByOrganizationId', { params })

/**
 * 根据当前组织的所有父级生成当前项目信息（计算logo和简介）
 * @param params
 */
export const getProjectInfoAPI = (params: { organizationId: number }) => request.get('/organization/getParents', { params })

/**
 * 账号激活
 * @param params
 */
export const activationAccountAPI = (params: { appRole?: string, userId:number }) => request.get('/userAndApp/activation', { params })

/**
 * 根据条件获取指定角色
 * @param params
 */
export const getShowApi = (params: { organizationId?: string, roleName?:string }) => request.get('/role/show', { params })
