import { RouteRecordRaw } from 'vue-router'
import Layout from '@/layout/LayoutView.vue'

const routes: Array<RouteRecordRaw> = [
  {
    path: '/video-management', name: 'VideoManagement',
    component: Layout, redirect: '/video-management/index',
    meta: { title: '视频管理', permission: '8' },
    children: [
      {
        path: 'index', name: 'VideoIndex',
        meta: { title: '摄像头', permission: '39' },
        component: () => import('@views/video/CameraView.vue'),
      },
      {
        path: 'camera-management', name: 'CameraManagement',
        meta: { title: '摄像头管理', permission: '40' },
        component: () => import('@views/video/CameraManage.vue'),
      },
    ]
  }
]

export default { routes, menu: true }
