import { RouteRecordRaw } from 'vue-router'
import Layout from '@/layout/LayoutView.vue'

const routes: Array<RouteRecordRaw> = [
  {
    path: '/scene-management', name: 'SceneManagement',
    component: Layout, redirect: '/scene-management/index',
    meta: { title: '场景管理', permission: '4' },
    children: [
      {
        path: 'index', name: 'SceneIndex',
        meta: { title: '场景', permission: '50' },
        component: () => import('@views/scene/sceneView.vue'),
      },
      {
        path: 'bind-equipment', name: 'BindEquipment',
        meta: { title: '绑定联网设备', isMenu: false, permission: '54' },
        component: () => import('@views/scene/bindEquipment.vue'),
      },
      {
        path: 'look-equipment', name: 'LookEquipment',
        meta: { title: '数据查看', isMenu: false, permission: '50' },
        component: () => import('@views/scene/lookEquipment.vue'),
      },
      {
        path: 'template', name: 'SceneTemplate',
        meta: { title: '场景模板', permission: '85' },
        component: () => import('@views/scene/sceneTemplate.vue'),
      },
      {
        path: 'save-scene-template', name: 'SaveSceneTemplate',
        meta: { title: '编辑场景模板', isMenu: false, permission: '86' },
        component: () => import('@views/scene/AddSceneTemplate.vue'),
      },
      {
        path: 'look-scene-template', name: 'LookSceneTemplate',
        meta: { title: '查看场景模板', isMenu: false, permission: '85' },
        component: () => import('@views/scene/AddSceneTemplate.vue'),
      }
    ]
  }
]

export default { routes, menu: true }
