// TODO 必填项 - 类型
import { ObjectUtil } from '@utils/object-util'

type ElRequired = { required: boolean, message: string, trigger: string }

type Required = {
  (message?: string): ElRequired,
  (message: string, trigger?: string): ElRequired,
  blur (message?: string): ElRequired,
  change (message?: string): ElRequired
}

function requiredFn (message = '此项为必填项', trigger = 'blur'): ElRequired {
  return {
    required: true,
    message,
    trigger: trigger
  }
}

['blur', 'change'].forEach(trigger => {
  (<any>requiredFn)[trigger] = (message: string) => {
    return requiredFn(message, trigger)
  }
})

// TODO 必填项
export const required: Required = <Required>requiredFn

// TODO 手动验证 - 类型
export interface ElValidator {
  validator (rule: any, value: any, callback: (error?: Error) => void): void,

  trigger: string
}

// TODO 手机号
export function phoneTag (value) {
// 中国电信号码格式验证 手机段：
  // 133,149,153,173,177,180,181,189,191,199,1349,1410,1700,1701,1702
  const phoneTagDx = /(?:^(?:\+86)?1(?:33|49|53|7[37]|8[019]|9[19])\d{8}$)|(?:^(?:\+86)?1349\d{7}$)|(?:^(?:\+86)?1410\d{7}$)|(?:^(?:\+86)?170[0-2]\d{7}$)/

  // 中国联通号码格式验证 手机段：
  // 130,131,132,145,146,155,156,166,171,175,176,185,186,1704,1707,1708,1709
  const phoneTagLt = /(?:^(?:\+86)?1(?:3[0-2]|4[56]|5[56]|66|7[156]|8[56])\d{8}$)|(?:^(?:\+86)?170[47-9]\d{7}$)/

  // 中国移动号码格式验证 手机段：
  // 134,135,136,137,138,139,147,148,150,151,152,157,158,159,178,182,183,184,187,188,198,1440,1703,1705,1706
  const phoneTagYd = /(?:^(?:\+86)?1(?:3[4-9]|4[78]|5[0-27-9]|78|8[2-478]|98)\d{8}$)|(?:^(?:\+86)?1440\d{7}$)|(?:^(?:\+86)?170[356]\d{7}$)/
  if (phoneTagDx.test(value) || phoneTagLt.test(value) || phoneTagYd.test(value)) {
    return undefined
  } else {
    return new Error('手机号格式不正确')
  }
}

export const phone: ElValidator = {
  validator (rule, value, callback) {
    callback(phoneTag(value))
  },
  trigger: 'blur'
}

// TODO 账号
export function usernameTag (value) {
  const username = /^[\w]{3,16}$/
  if (username.test(value)) {
    return undefined
  } else {
    return new Error('账号为4 ~ 16位字母、数字')
  }
}

export const username: ElValidator = {
  validator (rule, value, callback) {
    callback(usernameTag(value))
  },
  trigger: 'blur'
}

// TODO 密码
export function passwordTag (value) {
  const password = /^(?=.*[A-Za-z])(?=.*\d)[\w\-+.]{8,16}$/
  const passwordTwo = /^[\w\-+.]{8,16}$/
  if (password.test(value)) {
    return undefined
  } else if (passwordTwo.test(value)) {
    return new Error('密码至少包含1位字母或数字')
  } else {
    return new Error('密码为8 ~ 16位字母、数字')
  }
}

export const password: ElValidator = {
  validator (rule, value, callback) {
    callback(passwordTag(value))
  },
  trigger: 'blur'
}

// TODO 昵称|显示名称|名称
export function displayNameTag (value) {
  const displayName = /^[\u4e00-\u9fa5\w\d]{2,16}$/
  if (displayName.test(value)) {
    return undefined
  } else {
    return new Error('请输入2 ~ 16位汉字、字母或数字')
  }
}

export const displayName: ElValidator = {
  validator (rule, value, callback) {
    callback(displayNameTag(value))
  },
  trigger: 'blur'
}

// TODO 图形验证码
export function captchaTag (value) {
  const position = /^[a-zA-Z\d]{4}$/
  if (position.test(value)) {
    return undefined
  } else {
    return new Error('验证码格式错误')
  }
}

export const captcha: ElValidator = {
  validator (rule, value, callback) {
    callback(captchaTag(value))
  },
  trigger: 'blur'
}

// TODO 短信验证码
export function SMSCodeTag (value) {
  const position = /^[a-zA-Z\d]{6}$/
  if (position.test(value)) {
    return undefined
  } else {
    return new Error('验证码格式错误')
  }
}

export const SMSCode: ElValidator = {
  validator (rule, value, callback) {
    callback(SMSCodeTag(value))
  },
  trigger: 'blur'
}

// TODO 地理坐标
export function positionTag (value) {
  const position = /^((-?180)|(-?1[0-7]\d)|(-?[1-9]\d)|(-?[1-9])|0)(\.\d+)?,((-?90)|(-?[1-8]\d)|(-?[1-9])|0)(\.\d+)?$/
  if (ObjectUtil.isEmpty(value) || position.test(value)) {
    return undefined
  } else {
    return Error('坐标格式为(±180,±90)')
  }
}

export const position: ElValidator = {
  validator (rule, value, callback) {
    callback(positionTag(value))
  },
  trigger: 'blur'
}
// const a = /^(1[0-9]{2}|2[0-4][0-9]|25[0-5]|[1-9]?[0-9])(\.(1[0-9]{2}|2[0-4][0-9]|25[0-5]|[1-9]?[0-9])){3}$/;
