import request from './request'
import store from '@/store'
import { User } from '@typings/user'
import settings from '@/settings'

/**
 * 获取 Token
 */
export function getToken (): string {
  return localStorage.getItem('ato')
}

/**
 * 设置 Token
 * @param token
 */
export function setToken (token: string): void {
  request.defaults.headers.common[settings.HEADERS_TOKEN_KEY] = token
  localStorage.setItem('ato', token)
}

/**
 * 清除 Token
 */
export function removeToken (): void {
  const videoIds = localStorage.getItem('videoIds')
  const defaultCheckedKeys = localStorage.getItem('defaultCheckedKeys')
  localStorage.clear()
  localStorage.setItem('videoIds', videoIds)
  localStorage.setItem('defaultCheckedKeys', defaultCheckedKeys)
  sessionStorage.clear()
  store.commit('app/setUserInfo', {})
  store.commit('app/setProjectInfo', {})
  delete request.defaults.headers.common[settings.HEADERS_TOKEN_KEY]
}

/**
 * 授权验证
 * @param itemPermission
 * @param itemRole
 */
export function authorizationCheck (itemPermission = '' as string | string[], itemRole: string[] = []): boolean {
  if (!itemPermission && !itemRole.length) return true
  const user: User = store.getters['app/userInfo']
  if (!user.id) return false
  if (user.role === settings.ADMINISTRATOR_ROLE_ID) return true
  if (itemRole.indexOf(user.role) > -1) return true
  if (Array.isArray(itemPermission)) {
    for (let i = 0, len = itemPermission.length; i < len; i++) {
      const item = itemPermission[i].toString()
      if (user.permission.has(item)) return true
    }
    return false
  } else {
    return user.permission.has(itemPermission.toString())
  }
}
