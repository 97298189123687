export default [
  {
    title: '长度单位', base: 'm', unitList: [
      { unit: 'mm', ratio: 0.001 },
      { unit: 'cm', ratio: 0.01 },
      { unit: 'dm', ratio: 0.1 },
      { unit: 'm', ratio: 1 },
      { unit: 'km', ratio: 1000 },
      { unit: '万km', ratio: 10000000 },
    ]
  },
  {
    title: '质量单位', base: 'kg', unitList: [
      { unit: 'mg', ratio: 0.000001 },
      { unit: 'g', ratio: 0.001 },
      { unit: 'kg', ratio: 1 },
      { unit: 't', ratio: 1000 },
      { unit: '万t', ratio: 10000000 },
    ]
  },
  {
    title: '速度单位', base: 'km/h', unitList: [
      { unit: 'mm/s', ratio: 0.0036 },
      { unit: 'cm/s', ratio: 0.036 },
      { unit: 'dm/s', ratio: 0.36 },
      { unit: 'km/h', ratio: 1 },
      { unit: 'm/s', ratio: 3.6 },
      { unit: '万km/h', ratio: 10000 },
    ]
  },
  {
    title: '容量单位', base: 'm³', unitList: [
      { unit: 'mm³', ratio: 0.000000001 },
      { unit: 'cm³', ratio: 0.000001 },
      { unit: 'dm³', ratio: 0.001 },
      { unit: 'm³', ratio: 1 },
      { unit: 'km³', ratio: 1000 },
      { unit: '万km³', ratio: 10000000 },
    ]
  },
  {
    title: '面积单位', base: 'm²', unitList: [
      { unit: 'mm²', ratio: 0.000001 },
      { unit: 'cm²', ratio: 0.0001 },
      { unit: 'dm²', ratio: 0.01 },
      { unit: 'm²', ratio: 1 },
      { unit: 'km²', ratio: 100 },
      { unit: '万km²', ratio: 1000000 },
    ]
  },
  {
    title: '压力单位', base: 'Pa', unitList: [
      { unit: 'Pa', ratio: 1 },
      { unit: 'kPa', ratio: 1000 },
      { unit: 'MPa', ratio: 1000000 },
    ]
  },
  {
    title: '流量单位', base: 'm³/h', unitList: [
      { unit: 'mm³/s', ratio: 0.0000036 },
      { unit: 'cm³/s', ratio: 0.0036 },
      { unit: 'dm³/s', ratio: 3.6 },
      { unit: 'm³/h', ratio: 1 },
      { unit: 'km³/h', ratio: 1000000000 },
    ]
  },
  {
    title: '能耗', base: 'kWh', unitList: [
      { unit: 'kWh', ratio: 1 },
      { unit: 'MWh', ratio: 1000 },
    ]
  },
  {
    title: '功率', base: 'W', unitList: [
      { unit: 'W', ratio: 1 },
      { unit: 'kW', ratio: 1000 },
      { unit: 'MWh', ratio: 1000000 },
    ]
  },
  {
    title: '货币', base: '元', unitList: [
      { unit: '元', ratio: 1 },
      { unit: '万元', ratio: 10000 },
      { unit: '千万元', ratio: 10000000 },
      { unit: '亿元', ratio: 100000000 },
    ]
  },
  {
    title: '时间单位', base: 's', unitList: [
      { unit: 'ms', ratio: 0.001 },
      { unit: 's', ratio: 1 },
      { unit: 'min', ratio: 60 },
      { unit: 'h', ratio: 3600 },
    ]
  }
]
