exports.env = function () {
  return {
    // 开发环境变量
    // 修改BASE_API需要重跑
    development: {
      // BASE_API: 'http://192.168.99.123:8080',
      // BASE_API: 'http://182.92.208.167:8080',
      BASE_API: 'http://192.168.1.168:8080',
      // BASE_API: 'http://192.168.99.190:8080',
      // BASE_API: 'http://192.168.99.183:8080',
      // BASE_API: 'http://192.168.99.181:8080',
      // BASE_API: 'http://localhost:8080',
      // BASE_API: 'http://agwua9.natappfree.cc',
      // BASE_API: 'http://182.92.208.167:8082',
      // BASE_API: 'https://iottest.yuankonginfo.com',
      // BASE_API: 'http://192.168.227.233:8080',
      WEBSOCKET: 'ws://182.92.208.167:8080/websocket',
      // WEBSOCKET: 'ws://192.168.99.182:19977/websocket',
      IMAGE_PREFIX: 'http://182.92.208.167:81/'
    },
    // 生产环境变量
    production: {
      BASE_API: 'http://182.92.208.167:8080',
      // BASE_API: 'http://192.168.99.182:19998',
      IMAGE_PREFIX: 'http://182.92.208.167:81/',
      WEBSOCKET: 'ws://182.92.208.167:8080/websocket',
    }
  }
}
