import CryptoJS from 'crypto-js'
import settings from '@/settings'

/**
 * 加密
 * @param arg
 */
export const encrypt = (arg: string) => {
  return CryptoJS.AES.encrypt(arg, CryptoJS.enc.Utf8.parse(settings.ENCRYPT_KEY), {
    mode: CryptoJS.mode.ECB,
    padding: CryptoJS.pad.Pkcs7,
  }).toString()
}

/**
 * 解密
 * @param arg
 */
export const decrypt = (arg: string) => {
  return CryptoJS.AES.decrypt(arg, CryptoJS.enc.Utf8.parse(settings.ENCRYPT_KEY), {
    mode: CryptoJS.mode.ECB,
    padding: CryptoJS.pad.Pkcs7,
  }).toString(CryptoJS.enc.Utf8)
}
