import { env } from '@/env'

type UrlConfig = {
  BASE_API: string,
  IMAGE_PREFIX :string,
  WEBSOCKET: string,
  [p: string]: string
}
const urlConfig = env()[process.env.NODE_ENV] as UrlConfig

// 配置全局常量
const settings = {
  // 平台名称
  TITLE: '京灌科技管理平台',
  // token 失效 code
  TOKEN_FAILURE_CODE: 666,
  // 请求头token key
  HEADERS_TOKEN_KEY: 'YK',
  // 超级管理员角色ID(string)
  ADMINISTRATOR_ROLE_ID: '0',
  // 数据加密密钥(16位字母+数字)
  ENCRYPT_KEY: '2poi4jkn6hg7v425',
  ...urlConfig
}

export default settings
