import { RouteRecordRaw } from 'vue-router'
import Layout from '@/layout/LayoutView.vue'

const routes: Array<RouteRecordRaw> = [
  {
    path: '/universal-equipment', name: 'UniversalEquipment',
    component: Layout, redirect: '/universal-equipment/device',
    meta: { title: '通用设备管理', permission: '2' },
    children: [
      {
        path: 'device', name: 'UniversalDevice',
        meta: { title: '设备', permission: '19' },
        component: () => import('@views/device/universal/DeviceManage.vue'),
      },
      {
        path: 'add-device', name: 'AddUniversalDevice',
        meta: { title: '添加设备', permission: '21', isMenu: false, activeMenu: 'UniversalDevice' },
        component: () => import('@views/device/universal/AddUDevice.vue'),
      },
      {
        path: 'modify-device', name: 'ModifyUniversalDevice',
        meta: { title: '编辑设备', permission: '20', isMenu: false, activeMenu: 'UniversalDevice' },
        component: () => import('@views/device/universal/AddUDevice.vue'),
      },
      {
        path: 'show-device', name: 'ShowUniversalDevice',
        meta: { title: '数据查看', permission: '19', isMenu: false, activeMenu: 'UniversalDevice' },
        component: () => import('@views/device/universal/ShowDevice.vue'),
      },
      {
        path: 'variable-template', name: 'UniversalVariable',
        meta: { title: '设备变量模板', permission: '23' },
        component: () => import('@views/device/universal/VariableManage.vue'),
      },
      {
        path: 'batch-add-device', name: 'BatchAddUDevice',
        meta: { title: '批量添加设备', isMenu: false, activeMenu: 'UniversalVariable' },
        component: () => import('@views/device/universal/BatchAddUDevice.vue'),
      },
      {
        path: 'look-template', name: 'LookUniversalVariable',
        meta: { title: '查看变量模板', permission: '23', isMenu: false, activeMenu: 'UniversalVariable' },
        component: () => import('@views/device/universal/AddUVariable.vue'),
      },
      {
        path: 'add-template', name: 'AddUniversalVariable',
        meta: { title: '添加变量模板', permission: '25', isMenu: false, activeMenu: 'UniversalVariable' },
        component: () => import('@views/device/universal/AddUVariable.vue'),
      },
      {
        path: 'modify-template', name: 'ModifyUniversalVariable',
        meta: { title: '编辑变量模板', permission: '24', isMenu: false, activeMenu: 'UniversalVariable' },
        component: () => import('@views/device/universal/AddUVariable.vue'),
      },
    ]
  },
  {
    path: '/dedicated-equipment', name: 'DedicatedEquipment',
    component: Layout, redirect: '/dedicated-equipment/device',
    meta: { title: '专用设备管理', permission: '3' },
    children: [
      {
        path: 'device', name: 'SpecialDevice',
        meta: { title: '设备', permission: '46' },
        component: () => import('@views/device/special/DeviceManage.vue'),
      },
      {
        path: 'add-device', name: 'AddSpecialDevice',
        meta: { title: '添加设备', isMenu: false, activeMenu: 'SpecialDevice', permission: '48' },
        component: () => import('@views/device/special/AddSDevice.vue'),
      },
      {
        path: 'batch-add-device', name: 'BatchAddSDevice',
        meta: { title: '批量添加设备', isMenu: false, activeMenu: 'SpecialDevice', permission: '48' },
        component: () => import('@views/device/special/BatchAddSDevice.vue'),
      },
      {
        path: 'modify-device', name: 'ModifySpecialDevice',
        meta: { title: '编辑设备', isMenu: false, activeMenu: 'SpecialDevice', permission: '47' },
        component: () => import('@views/device/special/AddSDevice.vue'),
      },
      {
        path: 'show-device', name: 'ShowSpecialDevice',
        meta: { title: '数据查看', isMenu: false, activeMenu: 'SpecialDevice', permission: '46' },
        component: () => import('@views/device/special/ShowDevice.vue'),
      },
      {
        path: 'variable-template', name: 'SpecialVariable',
        meta: { title: '设备变量模板', permission: '82' },
        component: () => import('@views/device/special/VariableManage.vue'),
      },
      {
        path: 'look-template', name: 'LookSniversalVariable',
        meta: { title: '查看变量模板', isMenu: false, activeMenu: 'SpecialVariable' },
        component: () => import('@views/device/special/AddSVariable.vue'),
      },
      {
        path: 'add-template', name: 'AddSpecialVariable',
        meta: { title: '添加变量模板', isMenu: false, activeMenu: 'SpecialVariable' },
        component: () => import('@views/device/special/AddSVariable.vue'),
      },
    ]
  }
]

export default { routes, menu: true }
