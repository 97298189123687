/* eslint-disable */
import { App, Directive } from 'vue'

const modulesFiles = require.context('./modules', true, /\.ts$/)

const directive = modulesFiles.keys().reduce((modules: Record<string, Directive>, modulePath) => {
  const moduleName = modulePath.replace(/^\.\/(.*)\.\w+$/, '$1')
  const value = modulesFiles(modulePath)
  modules[moduleName] = value.default
  return modules
}, {})

export default {
  install (app: App<Element>) {
    for (let key in directive) {
      app.directive(key, directive[key])
    }
  }
}
